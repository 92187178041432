.App {
  text-align: center;
}

/* div{
  overflow-x: hidden;
} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


 /* CUSTOM SCROLLBAR */
        /* width */
        ::-webkit-scrollbar {
          width: 5px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
          border-radius: 5px;
        }
         
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #999; 
          border-radius: 5px;
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #bbb; 
        }